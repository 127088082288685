import { Component } from 'solid-js'
import { Portal } from 'solid-js/web'
import { ModalProps } from '~/components/modal/modal.interfaces'
import styles from '~/components/modal/modal.module.scss'
import CloseIcon from '~/assets/icons/close.svg'

const Modal: Component<ModalProps> = ({ children, title, open, setOpen }) => {
  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      {open && (
        <Portal>
          <div class={styles.wrapper}>
            <div class={styles.modal}>
              {title && (
                <div class={styles.title}>
                  {title}
                </div>
              )}
              <button
                class={styles.close}
                onClick={onClose}
                title='Fermer'
              >
                <CloseIcon class={styles.icon} />
              </button>

              {children}
            </div>
          </div>
        </Portal>
      )}
    </>
  )
}

export default Modal
